/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import Footer from '../components/Footer';
import bali from '../assets/bali.jpeg';
import hub1 from '../assets/hub-v1.mp4';
import hub2 from '../assets/hub-v2.mp4';
import hub3 from '../assets/hub-v3.mp4';
import hub4 from '../assets/hub-v4.mp4';
import React, { useEffect, useMemo, useState } from 'react';

export default function Hub() {
  const { t } = useTranslation();
  const [activeVideo, setActiveVideo] = useState<null | number>(null);

  const videoList = [hub1, hub2, hub3, hub4];

  const videoRefs = useMemo(() => (
    videoList.map(() => React.createRef<HTMLVideoElement>())
  ), [videoList.length]);

  for (let i = 0; i < videoList.length; i++) {
    videoRefs[i] = React.createRef<HTMLVideoElement>();
  }


  const playVideo = (index: number) => {
    // If there's an active video and it's not the one being clicked, pause it
    if (activeVideo !== null && activeVideo !== index) {
      const currentVideoElement = videoRefs[activeVideo].current;
      if (currentVideoElement) {
        currentVideoElement.pause();
      }
    }
  
    // If the clicked video is already active, do nothing; otherwise, set it as active
    if (activeVideo !== index) {
      setActiveVideo(index);
    } else {
      setActiveVideo(null);
    }
  };
  
  useEffect(() => {
    // Ensure that all other videos are paused when a new video is played
    videoRefs.forEach((ref: any, index: any) => {
      if (index !== activeVideo && ref.current) {
        ref.current.pause();
      }
    });
  }, [activeVideo, videoRefs]);

  return (
    <div style={{ backgroundImage: `url(${bali})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <div className='pt-[100px] lg:pt-[150px] bg-black/80 text-white pb-[100px] flex flex-col items-center gap-[60px] px-6'>
        <div className='flex flex-col items-center'>
          <h2 className='text-[20px] text-red-300 lg:text-[26px] mb-10 text-center font-bold px-6'>
          {t("hub.videos.title")}
          </h2>
          <div className='flex flex-wrap gap-6 items-center justify-center'>
            {videoList.map((video, index) =>
              <video
                key={`video-${index}`}
                ref={videoRefs[index]}
                className='overflow-hidden rounded-[200px] h-[315px] w-[315px] bg-red-600/50'
                style={{ boxShadow: '0 0 10px 10px rgba(60,10,38,0.5)' }}
                controls
                onClick={() => playVideo(index)}>
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            )}
          </div>
        </div>
        <div>
          <h2 className='text-[20px] text-red-300 lg:text-[26px] mb-6 text-center font-bold'>{t("hub.links.title")}</h2>
          <div className='flex flex-col gap-6'>
            <a   
              href="https://hackspirit.com/evening-rituals-of-people-who-are-happy-and-successful/">
            <p className='text-bold underline text-violet-300 text-[22px] lg:text-[26px] text-center' >{t("hub.links.body.1")}</p></a>
            <a   
              href="https://www.youtube.com/watch?v=v0eK4A43bMA">
            <p className='text-bold underline text-violet-300 text-[22px] lg:text-[26px] text-center' >{t("hub.links.body.2")}</p></a>
          </div>
        </div>
      </div>
      <Footer bg={false} />
    </div>
  )
}