
import { Routes, Route } from 'react-router-dom';
import Home from './routes/home.component';
import Header from './routes/header.component';
import Prices from './routes/prices.component';
import Program from './routes/program.component';
import Contact from './routes/contact.component';
import Hub from './routes/hub.component';

const App = () => {
  return (
    <Routes>
      < Route path="/" element={<Header />}>
        <Route index element={<Home />} />
        <Route path="/prices" element={<Prices />} />
        <Route path="/program" element={<Program />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/library" element={<Hub />} />
      </ Route>
    </Routes>
  );
};

export default App;
