import { useTranslation } from 'react-i18next';
import bali from '../assets/bali.jpeg';
import instagram from '../assets/instagram.png';
import whatsapp from '../assets/whatsapp.png';
import viber from '../assets/viber.png';
import clsx from './clsx';
import logo from "../assets/logo-head.png";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { structuredData } from '../routes/home.component';
import React from 'react';

const Footer = ({ addisionals, bg = true }: any) => {
  const { t } = useTranslation();
  const classNameLinks = 'hover:bg-red-300/40 flex gap-2 justify-between items-center cursor-pointer from-red-300/40 rounded-[10px]';
  const classNameLinksText = 'text-white z-10 italic';
  return(
    <div className='bg-black/90'>
    <div style={bg ? { backgroundImage: `url(${bali})`, backgroundSize: 'cover', backgroundPosition: 'center' }: {backgroundSize: '#0000'}} className=' w-full'>
      <div className='w-full h-full bg-black/80 flex flex-col items-center'>
        {addisionals && <div className='flex flex-col items-center px-5 border-b py-[30px] border-white'>
          <h2 className='text-[28px] mb-4 lg:text-[40px] text-red-200 text-center'>
            {t(addisionals[0]).split('-').map((item: string, key: number) => {
              return <React.Fragment key={key}>{item}<br /></React.Fragment>
            })}
          </h2>
          <p className='text-white text-[20px] lg:text-[26px] w-full tracking-tight text-left '>
            {t(addisionals[1]).split('.').map((item: string, key: number) => {
              return <React.Fragment key={key}>{item}<br /><br /></React.Fragment>
            })}
          </p>
        </div>}
        <div className='mt-[50px] w-full flex flex-col gap-6'>
          <h3 className='text-white italic lg:text-[22px] text-center px-5 tracking-wide'>{t('footer.text.1')}</h3>
          <div className='flex flex-col gap-5 lg:flex-row bg-red-200/10 items-center justify-around h-[100px] w-full cursor-pointer p-2 rounded'>
            <a href='https://www.instagram.com/petra_pispot/'
              className={clsx(classNameLinks, 'pr-2 bg-gradient-to-r')}>
              <img alt="pi-spot massage" src={instagram} className='w-[30px] lg:w-[40px]' />
              <p className={classNameLinksText}>{t('footer.instagram')}</p>
            </a>
            <a href="whatsapp://send?phone=+381652121100&text=Zdravo, kada imate slobodan termin?..."
              className={clsx(classNameLinks, 'flex-row-reverse pr-0 pl-2 justify-between bg-gradient-to-l')}>
              <img alt="pi-spot massage" src={whatsapp} className='w-[30px] lg:w-[40px]' />
              <p className={classNameLinksText}>{t('footer.whatsapp')}</p>
            </a>
            <a href="viber://contact?number=+381652121100" 
              className={clsx(classNameLinks, 'pr-2 bg-gradient-to-r')}>
              <img alt="pi-spot massage" src={viber} className='w-[30px] lg:w-[40px]' />
              <p className={classNameLinksText}>{t('footer.viber')}</p>
            </a>
          </div>
          <h3 className='px-5 text-white italic lg:text-[22px] text-center mb-4'>{t('footer.text.2')}</h3>
          <a href="tel:+381652121100" className='bg-red-200/10 text-center text-red-200 italic text-[22px] sm:text-[18px] lg:text-[30px] mb-4'>+381652121100</a>
          <div className='flex items-center justify-around w-full px-6 py-4'>
            <p className='text-white'>Copyright: @pi-spot Petra</p>
            <Link to="/" className='flex items-center gap-2'>
              <p className='text-white'>Pi Spot</p>
              <img alt="pi-spot massage" src={logo} width={30} />
            </Link>
          </div>
        </div>
      </div>
    </div>
    <Helmet>
      <title>{t("title")}</title>
      <meta name="description" content={t("about.body")} />
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </Helmet>
    </div>
  )
}

export default Footer;