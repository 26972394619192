import React from 'react';
import clsx from "./clsx";
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../redux/store';
import { triggerHam } from '../redux/ham';

const Hamburger = () => {
  const isHamburger = useSelector((state: State) => state.ham);
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(triggerHam(!isHamburger));
  };

  const containerClass = clsx('flex flex-col justify-between items-center relative w-[40px] h-[20px] z-10 opacity-50 cursor-pointer');

  // Adjust the line classes for the hamburger lines
  const firstLineClass = clsx("bg-violet-200 w-6 h-1 rounded absolute", 
    isHamburger ? "rotate-[45deg]" : "rotate-0", 
    "transition-all duration-500 ease-in-out", 
    isHamburger ? "top-[9px]" : "top-0"
  );

  const secondLineClass = clsx("bg-violet-200 w-6 h-1 rounded absolute",
    isHamburger ? "rotate-[-45deg]" : "rotate-0",
    "transition-all duration-500 ease-in-out",
    isHamburger ? "top-[9px]" : "top-[18px]"
  );

  return (
    <div className={containerClass} onClick={handleClick}>
      <div className={firstLineClass}></div>
      {/* Hide the middle line when in X state */}
      {!isHamburger && <div className="bg-blue-200 w-6 h-[4px] rounded mt-[9px]"></div>}
      <div className={secondLineClass}></div>
    </div>
  );
};

export default Hamburger;