const TRIGGER = "TRIGGER"

const initialState: boolean = false;

const hamReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case TRIGGER:
      return action.action
    default:
      return state;
  }
}

export default hamReducer;

export const triggerHam = (trigger: boolean) => {
  return {
    type: TRIGGER,
    action: trigger
  }
}