import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function PriceItem({title, time, price}: {title: string, time: string, price: string}) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  return (
    <><div className='flex justify-between border-b border-violet-200/50 mt-2'>
      <p className='lg:text-[18px]'>{title}</p>
      <button 
        className='text-red-300 px-4 hover:bg-red-600/50 border border-red-600'
        onClick={() => setShow(!show)}>{show ? t('hide') : t('show')}
      </button>
    </div>
    {show && <div className='flex flex-col justify-center'>
      <p className='text-red-100 lg:text-[16px]'>{time}</p>
      <p className='text-red-100 lg:text-[16px]'>{price}</p>
    </div>}
    </>
  )
}