import Video from '../assets/2.mp4';
import Footer from '../components/Footer';
import massage1 from '../assets/testb1.jpg';
import massage2 from '../assets/testb2.jpg';
import massage3 from '../assets/testb4.jpg';
import bali from '../assets/bali.jpeg';
import clsx from '../components/clsx';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import React from 'react';

export const structuredData = {
  "@context": "http://schema.org",
  "@type": "MassageBusiness",
  "name": "PI SPOT",
  "description": "A dedicated massage salon in the Vračar district of Belgrade offering customized relaxation, therapeutic, and sports massages.",
  "url": "https://pi-spot.rs/",
  "logo": "https://pi-spot.rs/static/media/logo2.e94946a05a21957df8c3.png",
  "contactPoint": {
    "@type": "ContactPoint",
    "telephone": "+381 65 21 21 100",
    "contactType": "customer support",
  },
  "sameAs": [
    "https://www.instagram.com/petra_pispot/",
  ],
};

const Home = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full h-screen">
      <div className="relative h-[800px] overflow-hidden" style={{ backgroundImage: `url(${bali})`, backgroundPosition: 'center' }}>
        <video autoPlay loop muted playsInline className="absolute z-0 w-auto min-w-full min-h-full max-w-none">
            <source src={Video} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
        <div className="relative z-10 flex flex-col items-center mt-[80px] px-5">
          <div className='px-5 flex flex-col justify-center my-[200px] max-w-[1200px] w-full items-center right-5 left-5 bg-red-200/20 h-[300px]'>
            <h2 className='text-white text-center text-xl tracking-wide'>{t('headline.title')}</h2>
            <h3 className='text-white text-center text-lg'>{t('headline.body')}</h3>
            <h2 className='text-white text-center text-[24px] mt-10 text-red-200'>{t('headline.quote')}</h2>
          </div>
        </div>
        <Helmet>
          <title>{t("title")}</title>
          <meta name="description" content={t('headline.body')} />
          <script type="application/ld+json">
            {JSON.stringify(structuredData)}
          </script>
        </Helmet>
      </div>
      <div className="bg-black/90 pt-20 flex flex-col items-center px-5">
        <p className='text-center text-[30px] lg:text-[35px] text-red-200 pb-4 max-w-[1000px] tracking-wider'>
          {t('welcome.title').split('–').map((item: string, key: number) => {
            return <React.Fragment key={key}>{item}<br /></React.Fragment>
          })}
        </p>
        <p className='text-white text-[24px] text-center lg:text-[26px] md:text-[20px] sm:text-[18px] w-full'>
        {t('welcome.body')}
        </p>
        <div className='pt-[100px] w-full max-w-[1200px] flex flex-col'>
        {list.map((item, i) => {
          const { img, title, body, link } = item;
          return (
            <Link to={link}
            key={`massage-${i}`}
            className={clsx('flex flex-col bg-gradient-to-r from-red-300/40 lg:flex-row items-center justify-between gap-8 my-[30px] hover:bg-red-300/40', 
            i === 0 || i === 2 ? 'self-start flex-row-reverse' : 'self-end')}>
              <div 
                style={{ backgroundImage: `url(${img})`, backgroundSize: 'cover', backgroundPosition: 'center' }} 
                className='h-[300px] max-w-[600px] w-full flex justify-center items-center' 
              ><button className='text-[30px] font-bold clickMe'>Click Me</button></div>
              <div className='w-full px-[10px] pb-[10px]'>
                <h3 className='text-red-200 text-[20px] tracking-wide'>{t(title)}</h3>
                <p className='text-white lg:text-[17px] tracking-normal text-justify'>{t(body)}</p>
              </div>
            </Link>
          )
        })}
        </div>
      </div>
      <Footer addisionals= {["about.title", "about.body"]} />
    </div>
  )
}

const list = [
  {
    img: massage1,
    title: 'therapeutic.title',
    body: 'therapeutic.body',
    link: '/program#therapeutic',
  },
  {
    img: massage2,
    title: 'sport.title',
    body: 'sport.body',
    link: '/program#sport',
  },
  {
    img: massage3,
    title: 'relax.title',
    body: 'relax.body',
    link: '/program#relax',
  },
]

export default Home;