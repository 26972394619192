import { useState, useEffect } from 'react';
import { Link, Outlet, useLocation } from "react-router-dom";
import clsx from "../components/clsx";
import logo from "../assets/logo2.png";
import Hamburger from "../components/hamburger";
import DropDown from '../components/DropDown';
import LanguageSwitcher from '../components/LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { triggerHam } from '../redux/ham';

const Header  = () => {
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const links = ['program', 'prices', 'library', 'contact']

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const headerClassName = clsx("w-full xl:h-[120px] absolute flex items-center justify-between px-3 lg:px-6 py-5 z-20 bg-red-600 shadow-2xl")
  return (
    <>
    <div className={headerClassName}>
      <Link onClick={() => dispatch(triggerHam(false))} to="/"><img alt="pi-spot massage" src={logo} width={isMobile ? 30 : 60} /></Link>
      {isMobile ? 
        <>
          <p className='text-violet-200 font-bold'>{t(location.pathname.slice(1))}</p>
          <Hamburger />
          <DropDown links={links} />
        </> 
        : 
        <>
        {
          links.map((v, i) => {
            const isActive = location.pathname === `/${v}`;
            return (
              <Link to={v} className={clsx(i % 2 === 0 ? 'self-start' : 'self-end')} key={`link-${i}`}>
                <p 
                  className={clsx('hover:text-violet-200 italic xl:text-[22px] text-base lg:text-base text-shadow-lg',
                    isActive ? 'text-violet-300 font-bold' : 'text-white')}
                >
                  {t(v)}
                </p>
              </Link>
            )
          }
          )
        }
          <LanguageSwitcher />
        </>}
    </div>
    <Outlet />
    </>
  )
}

export default Header;