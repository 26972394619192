import { useTranslation } from 'react-i18next';
import Footer from '../components/Footer';
import bali from '../assets/bali.jpeg';

export default function Contact() {
  const { t } = useTranslation();

  return (
    <div style={{ backgroundImage: `url(${bali})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <div className='pt-[100px] lg:pt-[200px] bg-black/80 text-white pb-[100px]'>
        <div className='text-center flex flex-col items-center'>
          <h2 className='text-[26px] lg:text-[40px] text-red-200 mb-[16px] md:text-[36px] '>{t('contactRoute.address')}</h2>
          <p className='md:text-[22px] lg:text-[26px]'>Generala Horvatovića 52, Beograd 11000</p>
            <iframe
              title="address PISpot"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2831.443140323264!2d20.484630276998157!3d44.79215687802593!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a707b7e07cbc5%3A0x34117e58df5023b7!2sGenerala%20Horvatovi%C4%87a%2052%2C%20Beograd%2011000!5e0!3m2!1sen!2srs!4v1700241932348!5m2!1sen!2srs"
              className='my-[12px] lg:w-[600px] lg:h-[600px] md:w-[400px] md:h-[400px]'
              loading="lazy" 
            >
            </iframe>
          <h2 className='text-[26px] text-red-200 my-[16px] md:text-[36px] lg:text-[40px]'>{t('contactRoute.hours.title')}</h2>
          <p className='md:text-[22px] lg:text-[26px]'>{t('contactRoute.hours.body')}</p>
        </div>
      </div>
      <Footer bg={false} />
    </div>
  )
}