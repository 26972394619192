import { t } from "i18next";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { structuredData } from "../routes/home.component";

export default function Massage({massage, i, image}: any) {

  const [more, setMore] = useState(false);

  return (
    <div style={{boxShadow: '0 0 10px 10px rgb(30, 30, 30, 0.1)'}}>
      <div 
        className='h-[150px] flex flex-col items-center justify-center' 
        style={{ backgroundImage: `url(${image})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
        onClick={() => setMore(!more)}
      >
        <h2 className='text-[26px] text-center lg:text-[30px] font-bold'>{t(`pricesRoute.${massage}.body.${i + 1}`)}</h2>
        <div className='flex flex-col items-center cursor-pointer text-white'>
          {!more && <p className='translate-y-[5px] text-[20px]'>{t("more")}</p>}
          <p className='text-[30px]'>{more ? '︽' : '︾' }</p>
          {more && <p className='translate-y-[-5px] text-[20px]'>{t("less")}</p>}
        </div>
      </div>
      {more && <p className='text-red-100 text-center text-[14px] lg:text-[18px] p-5'>{t(`programRoute.${massage}.body.${i + 1}`)}</p>}
        <Helmet>
          <title>{t(`pricesRoute.${massage}.body.${i + 1}`)}</title>
          <meta name="description" content={t(`programRoute.${massage}.body.${i + 1}`).slice(0, 100)} />
          <script type="application/ld+json">
            {JSON.stringify(structuredData)}
          </script>
        </Helmet>
    </div>
  )
}