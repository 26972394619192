import { useTranslation } from 'react-i18next';
import Footer from '../components/Footer';
import Massage from '../components/MassageItem';
import { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { structuredData } from './home.component';


export default function Program() {
  const { t } = useTranslation();

  const therapeuticRef = useRef<HTMLElement | null>(null);
  const sportRef = useRef<HTMLElement | null>(null);
  const relaxRef = useRef<HTMLElement | null>(null);

  function scrollToMassage(massage: any) {
    massage.current.scrollIntoView({ behavior: 'smooth' });
  }

  useEffect(() => {
    const hash = window.location.hash;
    if (hash === '#therapeutic' && therapeuticRef.current) {
      scrollToMassage(therapeuticRef)
    } else if (hash === '#sport' && sportRef.current) {
      scrollToMassage(sportRef)
    } else if (hash === '#relax' && relaxRef.current) {
      scrollToMassage(relaxRef);
    }
  }, []);

  const massages: {[key: string]: any} = { 
    therapeutic: {ref: therapeuticRef, 
      list: [
      'https://wientherapie.at/wp-content/uploads/Anti-Migraine-Massage.jpg',
      'https://relaxe.co/cdn/shop/articles/shoulder-and-neck-massage-benefits_1024x1024.jpg?v=1646373189', 
      'https://www.physio.co.uk/images/massage/reduced-muscle-spasm.jpg', 
      'https://images.everydayhealth.com/images/arthritis/rheumatoid-arthritis/why-you-should-get-a-massage-for-ra-pain-relief-1440x810.jpg', 
      'https://d2jx2rerrg6sh3.cloudfront.net/images/Article_Images/ImageForArticle_22825_16576273997625986.jpg',
       'https://cdn.shopify.com/s/files/1/0878/4288/articles/33_b3b38584-a708-45f4-85c4-73bd0043d4dd.png?v=1690671039',
       'https://www.aquasana.co.uk/treatments/massage-treatments/elemis-freestyle-deep-tissue-massage-55/_jcr_content/image.coreimg.jpeg/1648568526465/aqua-sana-mens-ultimate-back-revival.jpeg',
       'https://pemconfinement.com/blog/wp-content/uploads/2017/08/Top-Benefits-of-Postnatal-Massage-and-Confinement-Services.jpg']},
    sport: {ref: sportRef, 
      list: [
        'https://cdn.shopify.com/s/files/1/0493/9533/5327/files/unnamed_-_2023-06-27T012625.157_480x480.png?v=1687800571',
       'https://img.freepik.com/premium-photo/athletic-man-receiving-recovery-massage-by-physiotherapist-stretcher-therapeutic-back-massage-osteopathy-same-sports-center_242111-13082.jpg',
       'https://www.sportsinjuryclinic.net/wp-content/uploads/2019/05/benefits-of-massage800-800x426.jpg',
       'https://media.istockphoto.com/id/184857385/photo/hot-stone-massage-therapy.jpg?s=612x612&w=0&k=20&c=2EtO2FFRwk1HqEzamqaWpBnN07lWDqX3Pbeu43IjFRk=',
       'https://www.physio.co.uk/images/massage/content/increased-range-of-movement.jpg',
       'https://handandstone.ca/wp-content/uploads/2018/12/What-is-a-Sports-Massage.jpg']},
    relax: {ref: relaxRef, 
      list: ['https://www.goodnet.org/photos/620x0/28755_hd.jpg', 
    'https://www.kinetico.rs/wp-content/uploads/2013/03/antistress.jpg',
    'https://www.discovermassage.com.au/wp-content/uploads/2021/05/ways-massage-reduces-stress.jpg',
    'https://www.relaxkutak.rs/wp-content/uploads/2017/04/catwalkduo.png']}, }
  return (
    <>
    <div className="bg-black h-full pt-[100px] lg:pt-[200px] flex justify-center pb-[100px]">
      <div className="flex flex-col gap-10 px-5 text-white w-full max-w-[1000px] ">
        <div className='flex gap-[4px] justify-between items-center px-6'>
          {Object.keys(massages).map((key: string, i: any) => {
            return <button 
              key={`link-t-${i}`} 
              className='text-red-600 px-4 hover:bg-red-600/50 focus:bg-red-600/50'
              style={{ boxShadow: '0 0 5px 5px rgba(60,10,38,0.5)' }}
              onClick={() => scrollToMassage(massages[key].ref)}
            >
              {key[0].toUpperCase() + key.slice(1)}
            </button>
          })}
        </div>
        {Object.keys(massages).map((key: string, i:any) => {
  
          return (
            <div key={`pp${i}`} className='w-full p-4 border-b border-white/50' ref={massages[key].ref}>
              <h2 className='text-red-300 mb-2 lg:text-[30px] text-center font-bold'>{t(`pricesRoute.${key}.title`)}</h2>
              <p className='mb-2 lg:text-[26px] mb-[30px] text-red-100 font-bold text-center'>{t(`programRoute.${key}.description`)}</p>
              <div className='text-[12px] flex flex-col gap-8'>
                {massages[key].list.map((image: any, i: any) =>
                  <Massage massage={key} i={i} image={image} key={`ptp${i}`} />
                )}
              </div>
              <Helmet>
                <title>{t(`pricesRoute.${key}.title`)}</title>
                <meta name="description" content={t(`programRoute.${key}.description`).slice(0, 100)} />
                <script type="application/ld+json">
                  {JSON.stringify(structuredData)}
                </script>
              </Helmet>
            </div>
          )
        })}
      </div>
    </div>
    <Footer />
    </>
  )
}