import { useEffect, useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import clsx from "./clsx";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../redux/store";
import { useTranslation } from "react-i18next";
import { triggerHam } from "../redux/ham";

const DropDown = ({links}: {links: string[]}) => {
  const { t, i18n } = useTranslation();
  const isHamburger = useSelector((state: State) => state.ham);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (isHamburger) {
      setIsOpen(true);
    } else {
      // Delay the closing to allow the animation to play
      setTimeout(() => setIsOpen(false), 300);
    }
  }, [isHamburger]);

  const containerClass = clsx("z-20 absolute bg-red-600 left-0 right-[0px] top-[69px] px-6 py-6",
    isOpen ? "scale-100 opacity-100" : "scale-0 opacity-0");

  const content = ["switchLan", ...links]

  return (
    <div className={containerClass} style={{
      transition: "all 0.3s ease-in-out",
      transformOrigin: "top right"
    }}>
      <ul className="flex flex-col gap-4">
        {content.map((item, i) => (
          <li key={`manu-item${i}`}>
            {i === 0 ? 
            <><div className="flex justify-between items-center">
              <p className="text-white">{t(item)}</p>
              <div className={`w-10 h-6 flex items-center bg-gray-300 rounded-full cursor-pointer bg-gradient-to-r from-violet-300`} 
                onClick={() => i18n.language === 'en' ? i18n.changeLanguage('sr') : i18n.changeLanguage('en')}
              >
                <div 
                  className={clsx(`flex items-center justify-center text-green-400`, 
                    `bg-white w-6 h-6 rounded-full shadow-md transform duration-300 ease-in-out`,
                  `${i18n.language === 'en' ? 'translate-x-4' : ''}`)}
                >
                  <p>{i18n.language === 'sr' ? `🇷🇸` : `🇬🇧`}</p>
                </div>
              </div>
            </div>
            <div className="border-b border-white/30 mt-4 w-full">
              <p className="text-white/30 text-[16px]">Links</p>
            </div>
            </>
            : 
            <Link onClick={() => dispatch(triggerHam(false))} className={clsx(location.pathname === `/${item}` ? "text-violet-300 font-bold" : "text-white", 'cursor-pointer')} to={item}>{t(item)}</Link> }
          </li>
        ))}
      </ul>
    </div>
  )
}

export default DropDown;


