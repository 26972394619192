import { useTranslation } from 'react-i18next';
import clsx from './clsx';

export default function LanguageSwitcher() {
  const { i18n, t } = useTranslation();

  const toggleLanguage = () => {
    if (i18n.language === 'en') {
      i18n.changeLanguage('sr');
    } else {
      i18n.changeLanguage('en');
    }
  };

  return (
    <button onClick={toggleLanguage} 
      className={clsx('text-white bg-gradient-to-r from-violet-300 px-5 py-2 rounded-[20px]', 
      'hover:bg-violet-300')}>
      {i18n.language === 'sr' ? `${t('ln')} 🇷🇸` : `${t('ln')} 🇬🇧`}
    </button>
  );
}