import { useTranslation } from 'react-i18next';
import Footer from '../components/Footer';
import bali from '../assets/bali.jpeg';
import PriceItem from '../components/PriceItem';

export default function Prices() {
  const { t } = useTranslation();

  const massages: {[key: string]: any[]} = { 
    therapeutic: [{time: '30', price: 2500}, {time: '30', price: 2500}, {time: '30/45', price: '2500/3200'}, 
      {time: '30/45', price: '2500/3200'}, {time: '30/45', price: '2500/3200'}, {time: '30/45', price: '2500/3200'}, 
      {time: '60', price: '4000'}, {time: '30/45', price: '2500/3200'}], 
    sport: [{time: '45', price: 3500}, {time: '45', price: 3800}, {time: '45', price: 4100},
      {time: '45/60', price: '4100/4800'}, {time: '45/60', price: '4100/4800'}, {time: '60/90', price: '6000/8000'}], 
    relax: [{time: '60', price: 3000}, {time: '60', price: 3300}, 
      {time: '90', price: 5000}, {time: '60', price: 5800}]}
  return (
    <div style={{ backgroundImage: `url(${bali})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
    <div className="bg-black/90 h-full pt-[150px] lg:pt-[200px] flex justify-center">
      <div className="flex flex-col gap-10 px-5 text-white max-w-[1000px] w-full ">
        {Object.keys(massages).map((key: string, i: any) => {
          return (
            <div className='w-full' key={`mp${i}`}>
              <p className='text-red-200 mb-2 text-center lg:text-[20px]'>{t(`pricesRoute.${key}.title`)}</p>
              <div className='text-[12px]'>
                {massages[key].map((v: any, i: any) =>
                <PriceItem
                  title={t(`pricesRoute.${key}.body.${i + 1}`)}
                  price={`${t('price')}: ${v.price} din`}
                  time={`${t('time')}: ${v.time} min`}
                />
                  // <><div key={`tp${i}`} className='flex justify-between border-b border-violet-200/50 mt-2'>
                  //   <p className='lg:text-[18px]'>{t(`pricesRoute.${key}.body.${i + 1}`)}</p>
                  //   <button onClick={() => setShow(!show)}>{show ? t('hide') : t('show')}</button>
                  // </div>
                  // {show && <div className='flex flex-col justify-center'>
                  //   <p className='text-red-100 lg:text-[18px]'>{`${t('time')}: ${v.time}`}</p>
                  //   <p className='text-red-100 lg:text-[18px]'>{`${t('price')}: ${v.price} din`}</p>
                  // </div>}
                  // </>
                )}
              </div>
            </div>
          )
        })}
      </div>
    </div>
    <Footer bg={false} />
    </div>
  )
}